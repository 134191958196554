* {
    box-sizing: border-box;
}

.select_Year1 {
    width: 10rem;
    height: 4.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 1%;
    color: #4a69b1;
    border: 0.2rem solid #fefeff;
    font-weight: bold;
    font-size: 1.3rem;
    border-top-left-radius: 0.7rem;
    border-bottom-left-radius: 0.7rem;
    /* margin: 0.05rem; */
    text-align: center;
    background-color: #f9fafd;
}

.select_Year1:hover {
    color: #7384c0;
    box-shadow: 0.1rem 0.1rem 0.2rem #d7d6d9;
}

.select_Month1 {
    width: 10rem;
    height: 4.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 0.7%;
    color: #4a69b1;
    border: 0.2rem solid #fefeff;
    font-weight: bold;
    font-size: 1.3rem;
    /* margin: 0.05rem; */
    background-color: #ffffff;
    box-shadow: 0.2rem 0.1rem 0.5rem 0.2rem #f5f6f9;
}

.select_Month1:hover {
    color: #7384c0;
    box-shadow: 0.1rem 0.1rem 0.2rem #d7d6d9;
}

.select_week {
    width: 10rem;
    height: 4.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 1%;
    color: #4a69b1;
    border: 0.2rem solid #fefeff;
    font-weight: bold;
    font-size: 1.3rem;
    border-top-right-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    /* margin: 0.05rem; */
    justify-content: center;
    text-align: center;
    background-color: #f9fafd;
}

.select_week:hover {
    color: #7384c0;
    box-shadow: 0.1rem 0.1rem 0.2rem #d7d6d9;
}

.select_list {
    color: black;
    padding: 0.5rem;
    border: 0.2rem solid #d7d6d9;
    width: 7rem;
    text-align: center;
}

.select_list:first-child {
    display: none;
}

@media (max-width: 675px) {
    .select_Year1 {
        width: 8rem;
        font-size: 1.2rem;
    }
    .select_Month1 {
        width: 8rem;
        font-size: 1.2rem;
    }
    .select_week {
        width: 8rem;
        font-size: 1.2rem;
    }
}

@media (max-width: 500px) {
    .select_Year1 {
        width: 6rem;
        font-size: 1.1rem;
    }
    .select_Month1 {
        width: 6rem;
        font-size: 1.1rem;
    }
    .select_week {
        width: 6rem;
        font-size: 1.1rem;
    }
}

.ant-checkbox-inner{
    border: 1px solid #d4d4d4 !important;
}

.custom-ant-table th{
    font-size: 22px !important;
}

.custom-ant-table td{
    font-size: 18px !important;
}
.main__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background: #fcb539;
  height: 64px;
  width: 100%;
}

.main__header .left__header span {
  display: flex;
  justify-content: start;
  align-items: center;
}

.main__header .left__header span h3 {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #4a0600;
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.main__header .right__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}

.main__header .right__header .header__icon {
  margin-right: 20px;
}

.main__header .right__header .ant-badge-count {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #fcb539 !important;
  background: #4a0600 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.user__profile .user__profile__box {
  width: 240px;
  line-height: 38px;
  background: #fff;
  border-radius: 40px;
  position: relative;
}

.user__profile .user__profile__box span.title {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #4a0600;
  padding-left: 40px;
}

.user__profile .user__profile__box .ant-avatar {
  position: absolute !important;
  left: -20px !important;
  top: -6px !important;
  width: 50px !important;
  height: 50px !important;
  border: 2px solid #4a0600;
}

.user__profile .user__profile__box .ant-avatar.ant-avatar-icon {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caret__icon {
  margin-left: 5px;
}

.ant-dropdown-menu {
  width: 240px !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  margin-top: 15px !important;
}

@media screen and (max-width: 660px) {
  .main__header .left__header span h3 {
    font-size: 16px;
  }
  .user__profile .user__profile__box {
    width: 240px;
  }
  .main__header .right__header .header__icon {
    margin-right: 10px;
  }
  .main__header .right__header .header__icon.header__icon__msg {
    display: none;
  }
  .main__header .right__header .ant-badge-count {
    font-size: 15px;
  }
  .user__profile .user__profile__box span.title {
    font-size: 15px;
    padding-left: 40px;
  }
  .caret__icon {
    margin-left: 0;
  }
  .hidden-sm{
    display: none !important;
  }
}

@media screen and (max-width: 340px) {
  .user__profile .user__profile__box {
    width: 240px;
  }
  .user__profile .user__profile__box span.title {
    font-size: 15px;
    padding-left: 40px;
  }
}

.display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;
}
.timePicker input {
  width: 70px;
  height: 60px;
  border: 0 !important;
}
.timePicker input:hover,
.timePicker input:focus {
  border: 0px !important;
  transition: none !important;
}
.rc-time-picker-clear-icon {
  display: none;
}
.rc-time-picker-panel-inner {
  width: 92px;
}
.rc-time-picker-panel-select {
  width: 90px;
}

.timePicker {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 3px;
  border: #fbb438 solid 1px;
}
.timePicker:first {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.box1-calendar {
  float: right;
}

.label-c {
  font-weight: bold;
  font-size: 1.4rem;
  color: black;
}

.commodity-lists {
  color: #be222e;
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: bold;
  font-size: 1.9rem;
}
.rateModalTimer .ant-modal-close {
  display: none;
}
.rateModalTimer .ant-modal-header {
  display: none;
}
.rateModalTimer .ant-modal-body {
  padding: 0px !important;
  padding-bottom: 24px !important;
}
.antPerimeryButton:hover,
.antPerimeryButton.ant-btn-loading {
  color: black !important;
  border: 0px !important;
}

@import '~antd/dist/antd.less';
h1,
option,
select {
  text-transform: capitalize;
}
.react-calendar {
  z-index: 1000987;
}

.ant-tooltip-content {
  max-height: 300px !important;
  overflow: auto !important;
}

.postDetail:hover {
  background-color: rgb(247, 247, 247);
  cursor: pointer;
}

.NumberStyle {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

@primary-color: #de923b;@link-color: #f5f5f5;@border-radius-base: 6px;@border-color-base: #de923b;@error-color: red;@success-color: #de923b;
* {
  box-sizing: border-box;
}

.custom-calendar{
  border: 1px solid #fcb539;
  height: 30px;
  border-radius: 8px;
}

.calendar_Day,
.calendar_Month,
.calendar_Year{
  border: 0;
  border-radius: 0px;
  width: 100%;
  text-align: center;
}

.calendar_Day:focus-visible,
.calendar_Month:focus-visible,
.calendar_Year:focus-visible{
  outline: 0 !important;
  border: 0 !important;
}

  /* .select_Year {
    width: 7rem;
    height: 4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 1%;
    color: #4a69b1;
    border: .2rem solid #d7d6d9;
    font-weight: bold;
    font-size: 1.3rem;
    border-top-right-radius: .7rem;
    border-bottom-right-radius: .7rem;
    margin: 0.05rem;
    text-align: center;
  }
  
  .select_Year:hover {
    color: #7384c0;
    box-shadow: .1rem .1rem .2rem #d7d6d9;
  }
  
  .select_Month {
    width: 7rem;
    height: 4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 0.7%;
    color: #4a69b1;
    border: .2rem solid #d7d6d9;
    font-weight: bold;
    font-size: 1.3rem;
    margin: 0.05rem;
  }
  
  .select_Month:hover {
    color: #7384c0;
    box-shadow: .1rem .1rem .2rem #d7d6d9;
  }
  
  .select_Day {
    width: 7rem;
    height: 4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 1%;
    color: #4a69b1;
    border: .2rem solid #d7d6d9;
    font-weight: bold;
    font-size: 1.3rem;
    border-top-left-radius: .7rem;
    border-bottom-left-radius: .7rem;
    margin: 0.05rem;
    justify-content: center;
    text-align: center;
  }
  
  .select_Day:hover {
    color: #7384c0;
    box-shadow: .1rem .1rem .2rem #d7d6d9;
  }
  
  .select_list {
    color: black;
    padding: .5rem;
    border: .2rem solid #d7d6d9;
    width: 7rem;
    text-align: center;
  }
  
  .select_list:first-child {
    display: none;
  }
  
  @media(max-width: 992px){
    .select_Year {
      width: 5rem;
      height: 4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 1%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.2rem;
      border-top-right-radius: .7rem;
      border-bottom-right-radius: .7rem;
      margin: 0.05rem;
    }
    .select_Month {
      width: 5rem;
      height: 4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 0.7%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.2rem;
      margin: 0.05rem;
    }
    .select_Day {
      width: 5rem;
      height: 4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 1%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.2rem;
      border-top-left-radius: .7rem;
      border-bottom-left-radius: .7rem;
      margin: 0.05rem;
      justify-content: center;
      text-align: center;
    }
  }
  
  @media(max-width: 768px){
    .select_Year {
      width: 4.5rem;
      height: 4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 1%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.1rem;
      border-top-right-radius: .7rem;
      border-bottom-right-radius: .7rem;
      margin: 0.05rem;
    }
    .select_Month {
      width: 4.5rem;
      height: 4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 0.7%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.1rem;
      margin: 0.05rem;
    }
    .select_Day {
      width: 4.5rem;
      height: 4rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 1%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.1rem;
      border-top-left-radius: .7rem;
      border-bottom-left-radius: .7rem;
      margin: 0.05rem;
      justify-content: center;
      text-align: center;
    }
  }
  
  
  @media(max-width: 675px){
    .select_Year {
      width: 4rem;
      height: 3.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 1%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.1rem;
      border-top-right-radius: .7rem;
      border-bottom-right-radius: .7rem;
      margin: 0.05rem;
    }
    .select_Month {
      width: 4rem;
      height: 3.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 0.7%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.1rem;
      margin: 0.05rem;
    }
    .select_Day {
      width: 4rem;
      height: 3.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 1%;
      color: #4a69b1;
      border: .2rem solid #d7d6d9;
      font-weight: bold;
      font-size: 1.1rem;
      border-top-left-radius: .7rem;
      border-bottom-left-radius: .7rem;
      margin: 0.05rem;
      justify-content: center;
      text-align: center;
    }
  } */
  
* {
  box-sizing: border-box;
}

.Main_row {
  /* width: 100%; */
  background-color: #ffffff;
  /* margin-top: 33%;
    margin-left: 3%;
    margin-right: 3%; */
  box-shadow: 0.2rem 0.2rem 0.5rem 0.3rem #d7d6d9;

  border: 2px solid #eaf0f0;
  height: auto;
  display: flex;
  justify-content: end;
  margin: 10px;
  margin-top: 50px;
  align-items: center;
}

.top-feature-heading {
  color: #be2945;
  font-weight: bold;
  padding-left: 3.5%;
  padding-top: 1.5%;
}

.Mid_div {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: wrap;
  justify-content: space-between;
}

.calendar {
  margin-right: 3%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

@media (max-width: 1250px) {
  .Main_div {
    margin-top: 53%;
  }
}

@media (max-width: 1150px) {
  .Main_div {
    margin-top: 53%;
  }
}

@media (max-width: 1050px) {
  .Main_div {
    margin-top: 57%;
  }
}

@media (max-width: 970px) {
  .Main_div {
    margin-top: 60%;
  }
}

@media (max-width: 920px) {
  .Main_div {
    margin-top: 65%;
  }
}

@media (max-width: 915px) {
  .Main_div {
    margin-top: 108%;
  }
}

@media (max-width: 852px) {
  .Main_div {
    margin-top: 118%;
  }
}

@media (max-width: 782px) {
  .Main_div {
    margin-top: 130%;
  }
}

@media (max-width: 726px) {
  .Main_div {
    margin-top: 150%;
  }
}

@media (max-width: 675px) {
  .Main_div {
    margin-top: 150%;
  }
  .top-feature-heading {
    padding-left: 3%;
    font-size: 1.3rem;
    padding-top: 2.5%;
  }

  .calendar {
    margin-right: 3%;
    margin-top: 0.1%;
    margin-bottom: 0.5%;
  }
}

@media (max-width: 600px) {
  .Main_div {
    margin-top: 180%;
  }
}

@media (max-width: 550px) {
  .Main_div {
    margin-top: 200%;
  }
}

@media (max-width: 559px) {
  .Main_div {
    margin-top: 200%;
  }
}

@media (max-width: 500px) {
  .Main_div {
    margin-top: 220%;
  }
  .top-feature-heading {
    font-size: 1.2rem;
    padding-top: 3.5%;
  }
}

@media (max-width: 430px) {
  .Main_div {
    margin-top: 290%;
  }
}

* {
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}

.Main_Card {
  display: block;
  float: left;
  cursor: pointer;
  flex-wrap: wrap;
  /* margin-top: 3%; */
  /* margin-right: 1%; */
  width: 100%;
  /* margin-left: 3%; */
}

.card {
  font-size: 1.8rem;
  height: 12rem;
  /* border-radius: 7%; */
  box-shadow: 0.2rem 0.2rem 0.5rem 0.3rem #d7d6d9;
  background-color: #ffffff;
}

.content {
  padding-left: 10%;
  padding-top: 3%;
}
.split-card .content-left {
  padding-left: 10%;
  padding-top: 3% !important;
}
.content-left {
  padding-left: 10%;
  padding-top: 7%;
}
.content-left.card2,
.content-right.card2 {
  padding-left: 10%;
  padding-top: 3% !important;
}

.split-card .content-right {
  padding-left: 12%;
  padding-top: 3% !important;
}
.card2 {
  padding-left: 15%;
  padding-top: 3%;
}
.content-right {
  padding-left: 15%;
  padding-top: 7%;
}

.content-right-card4 {
  padding-left: 20%;
  padding-top: 3%;
}

.body {
  margin-top: -2rem;
  font-size: 1.3rem;
  /* font-weight: bold; */
  color: #0f0e0e;
}

.title {
  font-size: 3rem;
  font-weight: 600;
  color: #000000;
}

.split-card {
  display: flex;
  flex-direction: row;
  flex: 1 1;
}

/* @media (max-width: 500px) {
  .card {
    width: 27rem;
  }
} */

/* @media (max-width: 450px) {
  .card {
    width: 22rem;
  }
} */

.ql-toolbar.ql-snow {
  border-bottom: 1px solid #de923b !important;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  /* border: ; */
}

.ql-container.ql-snow {
  border: none !important;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0% !important;
}

.headingColor {
  color: #390400 !important;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  vertical-align: bottom !important;
}

.ant-upload.ant-upload-drag {
  border-style: solid !important;
}

.ant-upload-list-item-name {
  color: black;
}

.main-lower-div {
  display: block;
  float: left;
  margin-left: 2%;
}
.Main_Lower {
  background-color: #ffffff;
  width: 25.5rem;
  box-shadow: 0.2rem 0.2rem 0.5rem 0.3rem #d7d6d9;
  margin-top: 6%;
  margin-bottom: 4%;
  height: 487px;
}

.sizeBold {
  font-weight: 700;
  font-size: 16px;
}

.dropdown-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: space-between;
}

.image-div {
  width: 15rem;
  height: 15rem;
  background-color: #e8ebee;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  margin-top: 5%;
}

.img {
  width: 8rem;
  height: 8rem;
  /* border-radius: 50%; */
  position: relative;
  top: 21%;
}

.lower-heading {
  text-align: center;
  font-size: 2.1rem;
  color: #4a0600;
  font-weight: bolder;
}

.hr {
  border: 2px solid #4a0600;
  width: 60%;
  margin: auto;
  margin-top: -5%;
}

.Total {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;
  justify-content: space-between;
  line-height: 1.5rem;
  font-size: 1.7rem;
  text-align: start;
}

.text-color {
  color: #fcb945;
  font-weight: 600;
  font-size: 20px;
}


.progress-bar-card1 {
  -webkit-appearance: none;
  appearance: none;

  width: 100%;
  height: 12px;
  position: relative;
  top: -1rem;
}

.progress-bar-card1::-webkit-progress-bar {
  background-color: #dbdbdb;
  border-radius: 20px;
}

.progress-bar-card1::-webkit-progress-value {
  background-color: #fcb539;
  border-radius: 22px;
}

.progress-label-card1 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 18.5rem;
  margin-left: -9%;
  color: #000000;
}

.progress-bar-card2 {
  -webkit-appearance: none;
  appearance: none;

  width: 50%;
  height: 12px;
}

.progress-bar-card2::-webkit-progress-bar {
  background-color: #dbdbdb;
  border-radius: 20px;
}

.progress-bar-card2::-webkit-progress-value {
  background-color: #4a0600;
  border-radius: 22px;
}
.progress-label-card2-1 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 18.5rem;
  margin-left: 9.5%;
  color: #000000;
}

.progress-label-card2-2 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 18.5rem;
  margin-left: 15.5%;
  color: #000000;
}

.progress-bar-card3 {
  -webkit-appearance: none;
  appearance: none;

  width: 100%;
  height: 12px;
  position: relative;
  top: -1rem;
}

.progress-bar-card3::-webkit-progress-bar {
  background-color: #dbdbdb;
  border-radius: 20px;
}

.progress-bar-card3::-webkit-progress-value {
  background-color: #43dc80;
  border-radius: 22px;
}

.progress-label-card3 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 18.5rem;
  margin-left: -3%;
  color: #000000;
}

.progress-bar-card4 {
  -webkit-appearance: none;
  appearance: none;

  width: 50%;
  height: 12px;
}

.progress-bar-card4::-webkit-progress-bar {
  background-color: #dbdbdb;
  border-radius: 20px;
}

.progress-bar-card4::-webkit-progress-value {
  background-color: #d758e2;
  border-radius: 22px;
}

.progress-label-card4-1 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 34rem;
  margin-left: 9%;
  color: #000000;
}

.progress-label-card4-2 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 34rem;
  margin-left: 15.5%;
  color: #000000;
}

.progress-bar-card5 {
  -webkit-appearance: none;
  appearance: none;

  width: 50%;
  height: 12px;
}

.progress-bar-card5::-webkit-progress-bar {
  background-color: #dbdbdb;
  border-radius: 20px;
}

.progress-bar-card5::-webkit-progress-value {
  background-color: #2662f0;
  border-radius: 22px;
}

.progress-label-card5-1 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 34rem;
  margin-left: 9%;
  color: #000000;
}

.progress-label-card5-2 {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: 34rem;
  margin-left: 15.5%;
  color: #000000;
}


@media(max-width: 1250px){
  .progress-label-card1 {
    top: 18rem;
    margin-left: -10%;
  }

  .progress-label-card2-1 {
    top: 18rem;
    margin-left: 10%;
  }
  
  .progress-label-card2-2 {
    top: 18rem;
    margin-left: 17%;
  }

  .progress-label-card3 {
    top: 33rem;
    margin-left: -4%;
  }

  .progress-label-card4-1 {
    top: 33rem;
    margin-left: 10%;
  }
  
  .progress-label-card4-2 {
    top: 33rem;
    margin-left: 17%;
  }

  .progress-label-card5-1 {
    top: 48rem;
    margin-left: 10%;
  }
  
  .progress-label-card5-2 {
    top: 48rem;
    margin-left: 17%;
  }
}


/* @media(max-width: 1150px){
  .progress-label-card1 {
    top: 18rem;
    margin-left: -12%;
  }

  .progress-label-card2-1 {
    top: 18rem;
    margin-left: 12%;
  }
  
  .progress-label-card2-2 {
    top: 18rem;
    margin-left: 21%;
  }

  .progress-label-card3 {
    top: 32rem;
    margin-left: -5%;
  }

  .progress-label-card4-1 {
    top: 33rem;
    margin-left: 12%;
  }
  
  .progress-label-card4-2 {
    top: 33rem;
    margin-left: 21%;
  }

  .progress-label-card5-1 {
    top: 48rem;
    margin-left: 12%;
  }
  
  .progress-label-card5-2 {
    top: 48rem;
    margin-left: 21%;
  }
} */

/* @media(max-width: 1050px){
  .progress-label-card1 {
    position: absolute;
    top: 18rem;
    margin-left: -12%;
  }

  .progress-label-card2-1 {
    position: absolute;
    top: 18rem;
    margin-left: 12%;
  }
  
  .progress-label-card2-2 {
    position: absolute;
    top: 18rem;
    margin-left: 21%;
  }

  .progress-label-card3 {
    position: absolute;
    top: 32rem;
    margin-left: -5%;
  }

  .progress-label-card4-1 {
    position: absolute;
    top: 32rem;
    margin-left: 12%;
  }
  
  .progress-label-card4-2 {
    position: absolute;
    top: 32rem;
    margin-left: 21%;
  }

  .progress-label-card5-1 {
    position: absolute;
    top: 46.5rem;
    margin-left: 12%;
  }
  
  .progress-label-card5-2 {
    position: absolute;
    top: 46.5rem;
    margin-left: 21%;
  }
} */
/* 
@media(max-width: 915px){
  .progress-label-card1 {
    top: 17rem;
    margin-left: -15%;
  }

  .progress-label-card2-1 {
    top: 31rem;
    margin-left: 15%;
  }
  
  .progress-label-card2-2 {
    top: 31rem;
    margin-left: 26%;
  }

  .progress-label-card3 {
    top: 45rem;
    margin-left: -6%;
  }

  .progress-label-card4-1 {
    top: 59rem;
    margin-left: 14%;
  }
  
  .progress-label-card4-2 {
    top: 59rem;
    margin-left: 24%;
  }

  .progress-label-card5-1 {
    top: 73rem;
    margin-left: 14%;
  }
  
  .progress-label-card5-2 {
    top: 73rem;
    margin-left: 24%;
  }
} */


/* @media(max-width: 850px){
  .progress-label-card1 {
    top: 17rem;
    margin-left: -15%;
  }

  .progress-label-card2-1 {
    top: 31rem;
    margin-left: 15%;
  }
  
  .progress-label-card2-2 {
    top: 31rem;
    margin-left: 26%;
  }

  .progress-label-card3 {
    top: 44.5rem;
    margin-left: -6%;
  }

  .progress-label-card4-1 {
    top: 58.5rem;
    margin-left: 15%;
  }
  
  /* .progress-label-card4-2 {
    top: 58.5rem;
    margin-left: 26%;
  } */

  /* .progress-label-card5-1 {
    top: 72.5rem;
    margin-left: 15%;
  }
  
  .progress-label-card5-2 {
    top: 72.5rem;
    margin-left: 26%;
  }
} */ 

/* 
@media(max-width: 750px){
  .progress-label-card1 {
    top: 16rem;
    margin-left: -16%;
  }

  .progress-label-card2-1 {
    top: 30rem;
    margin-left: 15%;
  }
  
  .progress-label-card2-2 {
    top: 30rem;
    margin-left: 27%;
  }

  .progress-label-card3 {
    top: 43rem;
    margin-left: -6%;
  }

  .progress-label-card4-1 {
    top: 57rem;
    margin-left: 15%;
  }
  
  .progress-label-card4-2 {
    top: 57rem;
    margin-left: 27%;
  }

  .progress-label-card5-1 {
    top: 71rem;
    margin-left: 15%;
  }
  
  .progress-label-card5-2 {
    top: 71rem;
    margin-left: 27%;
  }
} */
/* 
@media(max-width: 675px){
  .progress-label-card1 {
    top: 20.5rem;
    margin-left: -18%;
  }

  .progress-label-card2-1 {
    top: 34rem;
    margin-left: 18%;
  }
  
  .progress-label-card2-2 {
    top: 34rem;
    margin-left: 31%;
  }

  .progress-label-card3 {
    top: 47.5rem;
    margin-left: -6%;
  }

  .progress-label-card4-1 {
    top: 61rem;
    margin-left: 18%;
  }
  
  .progress-label-card4-2 {
    top: 61rem;
    margin-left: 31%;
  }

  .progress-label-card5-1 {
    top: 74.5rem;
    margin-left: 18%;
  }
  
  .progress-label-card5-2 {
    top: 74.5rem;
    margin-left: 31%;
  }
} */
/* 
@media(max-width: 500px){

  .progress-label-card3 {
    top: 46rem;
    margin-left: -6%;
  }

  .progress-label-card4-1 {
    top: 59rem;
    margin-left: 18%;
  }
  
  .progress-label-card4-2 {
    top: 59rem;
    margin-left: 31%;
  }

  .progress-label-card5-1 {
    top: 72rem;
    margin-left: 18%;
  }
  
  .progress-label-card5-2 {
    top: 72rem;
    margin-left: 31%;
  }
} */
/* 
@media(max-width: 450px){
  .progress-label-card1 {
    top: 25rem;
    margin-left: -18%;
  }

  .progress-label-card2-1 {
    top: 38rem;
    margin-left: 18%;
  }
  
  .progress-label-card2-2 {
    top: 38rem;
    margin-left: 31%;
  }

  .progress-label-card3 {
    top: 42em;
    margin-left: -6%;
  }

  .progress-label-card4-1 {
    top: 63.5rem;
    margin-left: 18%;
  }
  
  .progress-label-card4-2 {
    top: 63.5rem;
    margin-left: 31%;
  }

  .progress-label-card5-1 {
    top: 76rem;
    margin-left: 18%;
  }
  
  .progress-label-card5-2 {
    top: 76rem;
    margin-left: 31%;
  }
} */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  display: block;
  background-color: #fff;
  width: 180px;
  min-width: 180px;
  border: 1px solid #fff;
  border-radius: 8px;
  z-index: 1;
  margin-top: 5px;
}

@media screen and (max-width: 480px) {
  .dropdown-content {
    min-width: 160px;
  }
}

@media screen and (max-width: 678px) {
  .dropdown-content {
    min-width: 170px;
  }
}

.dropdown-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-content ul li a,
.dropdown-content ul li button {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #262626;
  border: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: none;
  outline: 0;
  width: 100%;
  margin-bottom: 0;
}

.arrow-icon {
  border: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: none;
  outline: 0;
  margin-bottom: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.dropdown-content ul li a > svg,
.dropdown-content ul li button > svg {
  font-size: 20px;
  margin-right: 6px;
}

.dropdown-content ul li a:hover,
.dropdown-content ul li button:hover {
  background-color: #fcb539;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
}

/* Main Dashboard */
.main-dashboard {
  color: #242424;
  padding: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ant-card-body {
  padding: 12px !important;
}

.text-primary {
  color: #4a0600 !important;
}
.ant-select-selection {
  background-color: #fbc36a !important;
  color: white !important;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: white !important;
  font-weight: bold;
}

.buttonStyle {
  color: "#be2945";
  z-index: 999;
  font-weight: "bold";
  background-color: "transparent";
  text-align: "center";
}

.heading {
  color: #bc263d;
  margin-left: 5rem;
  font-weight: bold;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-right: 8px;
}

.para {
  color: #fbc36a;
  margin-left: 5rem;
  line-height: 0.5;
}

.box1 {
  display: flex;
  flex-direction: row;
  /* float: right; */
  position: absolute;
  right: 0;
  /* left: 65rem; */
  top: 7rem;

  flex-wrap: wrap;
  flex: 1 1;
}

.box2 {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  flex-wrap: wrap;
}

.label {
  font-weight: bold;
  font-size: 1.4rem;
  color: black;
}

@media (max-width: 992px) {
  .heading {
    margin-left: 4rem;
  }

  .para {
    margin-left: 4rem;
    line-height: 0.5;
  }

  .box1 {
    display: flex;
    flex-direction: row;
    /* float: right; */
    position: absolute;
    right: 0;
    /* left: 65rem; */
    top: 7rem;
    flex-wrap: wrap;
    flex: 1 1;
  }

  .box2 {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    flex-wrap: wrap;
  }

  .label {
    font-weight: bold;
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .heading {
    color: #bc263d;
    margin-left: 3rem;
    font-weight: bold;
    font-size: 1.3rem;
  }

  .para {
    color: #fbc36a;
    margin-left: 3rem;
    line-height: 0.5;
    font-size: 1.2rem;
  }

  .box1 {
    display: flex;
    flex-direction: row;
    /* float: right; */
    position: absolute;
    right: 0;
    /* left: 65rem; */
    top: 7rem;
    flex-wrap: wrap;
    flex: 1 1;
  }

  .box2 {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    flex-wrap: wrap;
  }

  .label {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

@media (max-width: 675px) {
  .heading {
    color: #bc263d;
    margin-left: 2rem;
    font-weight: bold;
  }

  .para {
    color: #fbc36a;
    margin-left: 2rem;
    line-height: 0.5;
  }

  .box1 {
    display: flex;
    flex-direction: row;
    position: relative;
    /* left: 65rem; */
    top: -0rem;
    float: none;
    flex-wrap: wrap;
    flex: 1 1;
  }

  .box2 {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    flex-wrap: wrap;
  }

  .label {
    font-weight: bold;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 576px) {
  .dashboard-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.react-date-picker__wrapper {
  border: 1px solid #fbc36a;
  border-radius: 8px;
  background: transparent !important;
}
.react-date-picker__inputGroup {
  border-radius: 8px;
}

.react-date-picker {
  border-radius: 8px;
}
.react-date-picker,
.react-date-picker span {
  background: #fff !important;
  font-size: 15px !important;
  font-weight: 500;
}

.react-date-picker__inputGroup__input:focus-visible {
  border: 0 !important;
  outline: 0 !important;
}

/* @media(max-width: 576px){
    .heading {
      color: #bc263d;
      margin-left: 2rem;
      font-weight: bold;
    }
    
    .para {
      color: #fbc36a;
      margin-left: 2rem;
      line-height: 0.5;
    }
  
    .box1 {
      display: flex;
    flex-direction: row;
    position: relative;
    /* left: 65rem; */
/* top: -0rem;
    float: none;
    flex-wrap: wrap;
    flex: 1 1;
      
    }
    
    .box2 {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      flex-wrap: wrap;
    }
    
    .label{
        font-weight: bold;
        font-size: 1.1rem;
    }
  } */

.dropDown {
  background-color: black;
  color: #ffffff;
  /* margin: 3.3%; */
  margin-top: 1%;
  margin-left: 0;
  border-radius: 0.3rem;
  border: 1px solid #fcb539;
  height: 3rem;
  box-shadow: 0.4rem 0.4rem 0.5rem #d7d6d9;
}

.ant-select-selection__placeholder {
  font-size: '12px' !important;
}

.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 12px;
}
.ant-select-selection__rendered {
  margin-right: 6px !important;
  margin-left: 4px !important;
}
.ant-select {
  font-size: 11px !important;
  font-weight: bold;
}
.dropDown-list {
  background-color: white;
  color: black;
}

.dropDown-list:first-child {
  display: none;
}

.heading {
  color: #bc263d;
  margin-left: 5rem;
  font-weight: bold;
}

.flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col-start{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-end{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mb-0{
  margin-bottom: 0 !important;
}

.ant-card-body{
  padding: 12px !important;
}

.text-primary{
  color: brown !important;
}

.ant-modal{
  top: 30px !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 4px;
  overflow-wrap: break-word;
}

.ant-table-tbody > tr > td {
  padding: 4px 4px;
  overflow-wrap: break-word;
}

.ant-table-thead > tr > th {
  padding: 4px 4px;
  overflow-wrap: break-word;
}

.ant-table-align-left,
.ant-table-row-cell-break-word {
  padding: 2px 2px;
}

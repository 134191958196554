.t{
    font-size: 12px;
    font-weight: bold; 
 }
 
 .p{
     color: #000;
     font-weight: bold;
     font-size: 18px;
     margin-left: 10px;
 }
 
 .tab-group{
     margin-top: 20px;
 }
 
 .order-box{
     border: 3px solid #000;
     color: #000;
     border-radius: 4px;
     text-align: center;
     margin-left: 8px;
     margin-top: 20px;
     padding: 28px 0;
 }
 
 .order-box .order-text, .order-box .order-count{
     font-size: clamp(22px, 2vw, 24px);
     font-weight: bold;
 }
 
 .order-box .order-text2, .order-box .order-count2{
     font-size: clamp(21px, 2vw, 23px);
     font-weight: bold;
 }
 
 .order-box .like-icon{
     font-size: 28px;
 }
 
 .order-box2{
     padding: 0;
 }
 
 .d-flex{
     display: flex;
     margin-bottom: 10px;
 } 
 .flex-column{
     flex-direction: column;
 }
 
 .ant-tabs{
     border-top: 5px solid #000;
 }
 
 .ant-tabs-tab{
     font-size: 16px !important;
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
     padding: 8px 38px !important;
     background-color: #000 !important;
     color: #fff;
     font-weight: bold !important;
 }
 
 .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
     display: none;
 }
 
 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
     text-shadow:  none !important;
 }
 
 
 
 .ant-tabs-tab + .ant-tabs-tab {
     margin: 0 0 0 12px !important;
 }
 
 .ant-checkbox-inner{
     border-radius: 4px !important;
     border: 0 !important;
     width: 25px !important;
     height: 25px !important;
 }
 
 
 .tb-image img{
     min-width: 80px;
     max-height: 80px;
 }
 
 .ant-checkbox {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     color: rgba(0, 0, 0, 0.85);
     font-size: 18px !important;
     font-variant: tabular-nums;
     line-height: 1.5715;
     list-style: none;
     font-feature-settings: 'tnum', "tnum";
     position: relative;
     top: 0.2em;
     line-height: 1;
     white-space: nowrap;
     outline: none;
     cursor: pointer;
 }
 
 .round-shape span{
     background-color: rgb(251, 180, 56);
     border-radius: 50px;
     padding: 5px 10px;
     margin-bottom: 10px;
     margin-top: 0 !important;
     margin-bottom: 10px !important;
 }
 
 .round-shape span p{
     font-size: 12px;
 }
 
 /* stage2
 
 .num-header, .call-header{
     border: 1px solid #000;
     margin-top: 10px;
     margin-left: 20px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     text-align: center;
     border-radius: 8px;
 }
 
 .num-header .add-cat, .num-header .add-num,
 .call-header .call-num
 {
     border-radius: 50px;
     background-color: rgb(251, 180, 56);
     color: #000;
     font-size: 13px;
     font-weight: bold;
     padding: 5px;
     margin-left: 10px;
 }
 .call-header .call-date{
     background-color: #000;
     color: #fff;
     font-weight: bold;
     padding: 10px;
     border: 1p solid #000;
     border-radius: 8px;
 
 }
 .call-header .call-num{
     background-color: transparent !important;
 }
 
 .num-header .total-num,
 .call-header .total-num
 {
     background-color: rgb(251, 180, 56);
     border-radius: 8px;
     padding: 5px;
     font-weight: bold;
 }
 
 .num-body-table, .num-body{
     border: 1px solid #000;
 }
 
 .num-body{
     margin-left: 20px;
     padding: 10px;
     display: flex;
     justify-content: space-around;
     align-items: center;
     border-top: 0;
     border-bottom: 0;
 }
 
 .num-body .facebook, .num-body .whatsapp,
 .num-body .facebook span, .num-body .whatsapp span{
     border-radius: 50px;
     background-color: brown;
     color: #000;
     font-size: 13px;
     font-weight: bold;
     padding: 5px;
     padding-left: 10px;
     margin-left: 10px;
 }
 
 .num-body .facebook span, .num-body .whatsapp span{
     background-color: rgb(251, 180, 56) !important;
 }
 .num-body-table, .call-body-table{
     padding: 10px;
     margin-left: 20px;
     border-top: 0;
     border-bottom-right-radius: 8px;
     border-bottom-left-radius: 8px;
 }
 
 .call-body-table{
     padding-top: 30px;
     padding-bottom: 10px;
     border: 1px solid #000;
     border-top: 0;
 }
 
 .num-body-table .num-body-row, .call-body-table .call-body-row,
 .call-body-table .review-body-row {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-left: 20px;
     font-size: 16px;
     font-weight: bold;
     margin-bottom: 10px;
 }
 
 .call-body-table .review-body-row span button{
     border-radius: 8px;
     background-color: rgb(251, 180, 56);
     color: #000;
     font-size: 13px;
     font-weight: bold;
     padding: 4px 20px;
     margin-right: 10px;
 }
 
 .call-body-table .call-body-row span .round-img{
     border-radius: 50%;
     width: 50px;
     height: 50px;
 }
 
 .call-body-table .call-body-row .btn-round{
     border-radius: 50%;
     width: 50px;
     height: 50px;
     background-color: rgb(251, 180, 56);
     text-align: center;
     font-size: 11px;
     font-weight: bold;
     margin-right: 5px;
 } */
 
 .m-header{
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: 10px;
     margin-left: 20px;
 }
 
 .call-header .pprofile{
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
     background-color: rgb(251, 180, 56);
     padding: 10px;
     margin-bottom: 32px;
     font-size: 13px;
     font-weight: bold;
 }
 
 .m-header .m-total-slh,.m-header .m-total-idl{
     width: 50%;
     font-size: 14px;
     font-weight: bold;
     text-align: center;
 }
 .m-header .m-total-slh{
     border-top-left-radius: 8px;
     border-bottom-left-radius: 8px;
 }
 .m-header .m-total-idl{
     border-top-right-radius: 8px;
     border-bottom-right-radius: 8px;
 }
 
 .box-round{
     width: 90px;
     height: 90px;
     border-radius: 100%;
     display: flex;
     background: #67d700;
     justify-content: center;
     align-items: center;
     flex-direction: column;
   }
 
 
 
 @media screen and (min-width: 330px) and (max-width: 992px){
     /* .total{
         padding-left: 0 !important;
     }
     .order-box{
         margin-left: 0 !important;
         margin-bottom: 10px;
         margin-right: 5px;
     }
     .order-box2{
         margin-left: 0 !important;
         margin-bottom: 10px;
         margin-right: 5px;
     }
     .ant-tabs-tab{
         font-size: 14px !important;
         padding: 8px 28px !important;
     }
     .call-body-table2{
         overflow: scroll;
     }
 
         .order-box .order-text, .order-box .order-count,
     .order-box2 .order-text2, .order-box2 .order-count2
     {
         font-size: clamp(20px, 50% + 10px, 20px);
         font-weight: bold;
     }
 
     .order-box2 .order-text-reqac, .order-box2 .order-text-reqre,
     .order-box2 .order-count-reqac, .order-box2 .order-count-reqre{
         font-size: 4vh;
         font-weight: bold;
     }
 
     .order-box2 .order-text-gsm, .order-box2 .order-count-gsm,
     .order-box2 .order-text-rsm, .order-box2 .order-count-rsm{
         font-size: 17px;
         font-weight: bold;
     }
 
     .order-box2 .order-text4, .order-box2 .order-count4{
         font-size: 13px;
         font-weight: bold;
     }
 
     .order-box2 .order-text3, .order-box2 .order-count3{
         font-size: 17px;
         font-weight: bold;
     }
 
     .order-box2 .order-text-like, .order-box2 .order-count-like,
     .order-box2 .order-text-dislike, .order-box2 .order-count-dislike{
         font-size: 14px;
         font-weight: bold;
     }
 
     .order-box2 .like-icon{
         font-size: 22px;
     }
 
     .round-shape span{
         background-color: rgb(251, 180, 56);
         border-radius: 50px;
         padding: 5px;
         margin-top: 0 !important;
         margin-bottom: 10px !important;
         margin-right: 5px;
     }
 
     .round-shape span p, .round-shape span p span{
         font-size: 12px !important;
         margin-bottom: 0 !important;
     }
 
     .box-round{
         width: 50px;
         height: 50px;
         border-radius: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         margin-right: 5px;
     }
 
     .box-round p{
         padding-left: 10px !important;
         font-size: 10px !important;
     }
 
     .box-round p + p{
         padding-left: 0 !important;
     }
 
     .u{
         max-height: 94px;
     } */
 
 }
 
 @media screen and (min-width: 993px) and (max-width: 1300px){
     /* .round-shape span{
         background-color: rgb(251, 180, 56);
         border-radius: 50px;
         padding: 5px;
         margin-top: 0 !important;
         margin-bottom: 10px !important;
         margin-right: 5px;
     }
     
     .round-shape span p, .round-shape span p span{
         font-size: 14px !important;
         margin-bottom: 0 !important;
     }
     
     .box-round{
         width: 70px;
         height: 70px;
         border-radius: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         margin-right: 5px;
       }
     
       .box-round p{
           padding-left: 12px !important;
           font-size: 12px !important;
       }
 
       .round-shape span{
         background-color: rgb(251, 180, 56);
         border-radius: 50px;
         padding: 7px;
         margin-top: 0 !important;
         margin-bottom: 10px !important;
         margin-right: 5px;
     }
     
     .round-shape span p, .round-shape span p span{
         font-size: 14px !important;
         margin-bottom: 0 !important;
     }
     .order-box .order-text, .order-box .order-count,
     .order-box2 .order-text2, .order-box2 .order-count2
     {
         font-size: 20px;
         font-weight: bold;
     }
 
     .order-box2 .order-text-reqac, .order-box2 .order-text-reqre,
     .order-box2 .order-count-reqac, .order-box2 .order-count-reqre{
         font-size: 17px;
         font-weight: bold;
     }
 
     .cp{
         padding: 21px 0 !important;
     }
 
     .order-box2 .order-text-gsm, .order-box2 .order-count-gsm,
     .order-box2 .order-text-rsm, .order-box2 .order-count-rsm{
         font-size: clamp(16px, 50% + 5px, 17px);
         font-weight: bold;
     }
 
     .order-box2 .order-text4, .order-box2 .order-count4{
         font-size: 13px;
         font-weight: bold;
     }
 
     .order-box2 .order-text3, .order-box2 .order-count3{
         font-size: 13px;
         font-weight: bold;
     }
 
     .order-box2 .order-text-like, .order-box2 .order-count-like,
     .order-box2 .order-text-dislike, .order-box2 .order-count-dislike{
         font-size: 15px;
         font-weight: bold;
     }
 
     .order-box2 .like-icon{
         font-size: 18px;
     }
     .u{
         max-height: 94px;
     } */
 }
 
 @media only screen and (max-width: 600px) {
     .ml-sm-0{
         margin-left: 10px !important;
     }
 }
 
 
 @media only screen and (min-width: 600px) {
     .ml-sm-0{
         margin-left: 10px !important;
     }
     .pap p{
         font-size: 12px !important;
         text-align: center;
     }
     .order-text-sm{
        font-size: clamp(22px, 2vw, 24px) !important;
     }
 }
 
 @media only screen and (min-width: 768px) {
     .ml-sm-0{
         margin-left: 10px !important;
     }
     .total{
         padding-left: 0 !important;
     }
     .pap p{
        font-size: 11px !important;
    }
    .order-text-md{
        font-size: clamp(22px, 2vw, 24px) !important;
     }
 }
 
 @media only screen and (min-width: 992px) {
     .ml-sm-0{
         margin-left: 10px !important;
     }
     .total{
         padding-left: 0 !important;
     }
     .pap p{
        font-size: 12px !important;
    }
    .ml-lg7{
        margin-left: 70px;
    }
    .order-box .order-text, .order-box .order-count{
        font-size: clamp(19px, 2vw, 21px);
        font-weight: bold;
    }

    .order-box .order-text2, .order-box .order-count2{
        font-size: clamp(18px, 2vw, 20px);
        font-weight: bold;
    }
    
    .order-box .order-text-lg2{ 
         font-size: clamp(16px, 2vw, 18px) !important;
    }
 }
 
 @media only screen and (min-width: 1200px) {
     .ml-sm-0{
         margin-left: 10px !important;
     }

     .order-box .order-text-lg2{ 
        font-size: clamp(20px, 2vw, 22px) !important;
   }
    
 }
 

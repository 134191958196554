.mandigurus-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 550px;
  color: white !important;
}
.mandigurus-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.drop-down {
  width: 22rem;
  height: 3.5rem;
  /* border: 0.19rem solid #4a0600; */
  color: #4a0600;
  font-weight: bold;
  background-color: #ffffff;
  /* margin-top: 10%; */
  /* padding: 2% 4%; */
}
.drop-down-list{
  color: black;
  padding: .5rem;
  /* text-align: center; */
  font-weight: bold;
}
/* .drop-down-list:first-child {
  display: none;
} */
.province {
  margin-left: 5%;
  margin-right: 2%;
}
.category {
  margin-right: 2%;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.login-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding-top: 3%;
}

.logo {
  width: 300px;
}

.card {
  padding-left: 5px !important;
}

.card2 .body {
  width: 100%;
  position: relative;
  left: -20px;
}

.rates_div {
  /* width: 100%; */
  height: auto;
  background-color: #fcb539;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  margin-top: 1%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 0.3%;
  /* justify-content: space-evenly; */
}

.mid_div {
  width: 28%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
}

.heading_div {
  font-size: 3rem;
  padding-left: 2%;
  padding-top: 1%;
  text-transform: capitalize;
  color: #4a0600;
}

.img_rate {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  margin: 0.5% 3%;
}

.link_rates {
  float: right;
  font-size: 1.3rem;
  padding: 2% 8%;
  position: absolute;
  right: 0;
  text-decoration: underline;
  color: #4a0600;
  font-weight: bold;
}

.icon {
  color: #4a0600;
}

.icon_Button {
  width: 3rem;
  height: 3rem;
}

.button_div {
  padding: 1.5% 3.5%;
  position: absolute;
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 0;
}

.table_div {
  /* width: 100%; */
  /* height: auto;
    display: flex;
    flex-direction: row;
    flex: 1 1; */
  overflow: auto;
  margin-left: 2%;
  margin-right: 2%;
  background-color: #ffffff;
  white-space: nowrap;
}

.table_rate {
  width: 100%;
  height: auto;
  /* margin-left: 5%;
    margin-right: 3%; */
  border-collapse: collapse;
  justify-content: space-between;
}

/* th {
  border-bottom: 1px solid #e3e3e3;
  text-align: left;
  font-size: 1.5rem;
  color: #fdbe4a;
  text-align: center !important;
} */

.set-timmer {
  font-size: 1.2rem;
  color: #4a0600;
  cursor: pointer;
  text-decoration: underline;
}

/* td {
  border-bottom: 1px solid #e3e3e3;
  text-align: left;
  font-size: 1.5rem;
  padding: 1% 2%;
  color: black;
} */

.data-row:hover {
  background-color: #e8e8e8;
}
.data-row.disable {
  background-color: lightslategray;
}
.data-row.active {
  background-color: lightgreen;
}
.schedule-icon {
  color: #4a0600;
}

.user-input-button {
  background-color: #fcb539;
  border: 1px solid #fcb539;
  border-radius: 10%;
  padding: 2% 5%;
  box-shadow: 0.2rem 0.2rem 0.5rem #ece1d5;
  color: #4a0600;
  font-weight: bold;
  cursor: pointer;
}

.thumb-up {
  color: #fcb539;
}

.thumb-down {
  color: #4a0600;
}

.delete_icon {
  color: #4a0600;
}

.dialog-title {
  font-size: 2rem;
  font-weight: bold;
}

.dialog-content {
  font-size: 1.5rem;
}

.dialog-user-input-title {
  background-color: #fcb539;
  padding: 0%;
  width: 100%;
  margin: 0%;
}

.user-input-title {
  font-size: 1.5rem;
  padding: 0%;
  font-weight: bold;
}

.dialog-user-input-content {
  padding: 0%;
  /* width: 100%; */
  margin: 0%;
}

.th-user-input th {
  border-bottom: 1px solid #e3e3e3;
  text-align: left;
  font-size: 1.5rem;
  padding: 2% 2%;
  color: #fdbe4a;
}

.td-user-input td {
  border-bottom: 1px solid #e3e3e3;
  text-align: left;
  font-size: 1.3rem;
  padding: 2% 2%;
  color: black;
}

.notify-wrapper {
  padding: 30px;
}

.notify-wrapper .outer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.panel-title {
  text-transform: capitalize;
}
.ant-collapse,
.ant-collapse > .ant-collapse-item,
.ant-collapse-content {
  border-bottom: 0 !important;
  border: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  margin-bottom: 10px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 12px;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0 !important;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0 !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  background-color: #e0e0e0;
  border-top: 0;
  border-radius: 12px;
  margin-top: 10px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 22px 24px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-radius: 12px !important;
  position: relative;
  color: #000;
  background-color: rgb(251, 180, 56);
  margin-bottom: 10px;
}

.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 10px 22px 10px 30px;
  border-radius: 12px;
  margin-top: 10px;
  background-color: #e0e0e0;
}

.panel-body .panel-wrapper,
.switch {
  display: flex;
}

.switch {
  justify-content: end;
  align-items: center;
}

.switch .ant-switch {
  margin-top: 10px;
}

.panel-body .panel-wrapper .panel-icon {
  margin-right: 20px;
  font-size: 28px;
  margin-top: 0;
  vertical-align: top;
}

.panel-body .panel-wrapper .panel-time {
  margin-left: auto;
}

.panel-body .panel-wrapper .panel-list-items li {
  list-style-type: square;
  margin-top: 5px;
}

.edit-btn {
  margin-left: 20px;
  margin-top: 2px;
  color: rgb(251, 180, 56);
  font-size: 18px;
}

.heading {
  color: #bc263d;
  margin-left: 5rem;
  font-weight: bold;
}

h1.ant-typography {
  margin-bottom: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 4px;
  overflow-wrap: break-word;
}

.ant-table-tbody > tr > td {
  padding: 4px 4px;
  overflow-wrap: break-word;
}

.ant-table-thead > tr > th {
  padding: 4px 4px;
  overflow-wrap: break-word;
}

.ant-table-align-left,
.ant-table-row-cell-break-word {
  padding: 2px 2px;
}

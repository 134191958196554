.drop-down {
  width: 22rem;
  height: 3.5rem;
  /* border: 0.19rem solid #4a0600; */
  color: #4a0600;
  font-weight: bold;
  /* background-color: #ffffff; */
  /* box-shadow: 0.3rem 0.3rem 0.5rem #d7d6d9; */
  /* margin-top: 10%; */
  /* padding: 2% 4%; */
  margin: 10px;
}
.drop-down-list {
  color: black;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
}
/* .drop-down-list:first-child {
  display: none;
} */
.province {
  margin-left: 5%;
  margin-right: 2%;
}
.category {
  margin-right: 2%;
}
.addvedio .drop-down {
  margin-top: 0 !important;
  padding: 0% !important;
  border: 0 !important;
}
